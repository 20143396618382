import React from "react";
import "../Modal/voteModal.css";
import TgIcon from "../Modal/images/tg.svg";

const VoteModal = ({ onClose }) => {
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  return (
    <div
      className="modal-background"
      style={{ width: "100vw", height: "100vh" }}
      onClick={handleBackgroundClick}
    >
      <div className="modal-content">
        <div className="text-modal1">
          <p>ВАШ ГОЛОС ЗАРАХОВАНО!</p>
        </div>

        <div className="text-modal2">
          <p>Для підтвердження голосування підпишіться</p>
          <p>на телеграм-канал "єДніпро"</p>
          <p>та чекай другого етапу голосування</p>
        </div>

        <div className="button-modal-div">
          <a
            href="https://t.me/kp_ednipro"
            style={{ textDecoration: "none", color: "black" }}
          >
            <button className="button-modal">
              <span>Підписатись</span>
              <img src={TgIcon} alt="Telegram" />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default VoteModal;
